import { setNuxtCtx } from '~/utils/nuxt-internal'

const isMobileUA = (ua) =>
  /(iPad|iPhone|iPod|Opera Mini|Android.*Mobile|NetFront|PSP|BlackBerry|Windows Phone)/gi.test(ua)

// 重定向请求的中间件
//
// 每次请求只会匹配一个规则，只要匹配成功，to返回参数或者validator内调用callback函数，则会重定向并跳出每次请求
// *** 如果没有validator，则默认匹配mobile浏览器的UA头 ***
//
// rule: 路由匹配生效条件正则
// to: 重定向后的路由地址，函数入参是from path，即重定向前匹配的地址
// statusCode: 重定向后的http状态码，默认是302
// validator: 重定向自定义函数，会覆盖上述3个字段
//      入参1是req对象
//      入参2是redirect回调函数，同nuxt的redirect用法

const redirectMap = [
  { rule: /lib\/?$/, to: () => '/m/' },
  { rule: /login/, to: () => '/m/login/' },
  { rule: /^\/topic\/?$/, to: () => '/m/topic' },
  { rule: /^\/topic\/[a-z]+$/, to: (from) => `/m${from}` },
  { rule: /lib\/recommend/, to: () => '/m/lib/recommend/' },
  {
    rule: /^\/search/,
    to: (from) => `/m${from}`.replace('keyword', 'keywords'),
  },
  {
    rule: /lib_detail_full/,
    to: (from) => from.replace('lib_detail_full', 'm/lib_detail'),
  },
  { rule: /^\/topic_list\/?$/, to: () => '/m/lib/list/' },
  {
    rule: /topic_detail/,
    to: (from) => from.replace('topic_detail', 'm/lib/detail'),
  },
  { rule: /static_history/, to: () => '/m/lib/history/' },
  { rule: /wxbusiness/, to: () => 'https://app.wecom.work/322088' },
  {
    validator: ({ url }, callback) => {
      const rule = /lib\/hot/
      if (rule.test(url)) {
        callback(301, '/lib/recommend/')
      }
    },
  },
  { rule: /^\/list/, to: () => '/m/list/' },
]

// 根据路由白名单，重定向到对应的路由
export default function (ctx) {
  const { req, redirect } = ctx
  // 挂载nuxt上下文
  setNuxtCtx(ctx)

  // 需要兼容static构建
  if (process.server && !process.static) {
    const { headers, url } = req
    const UA = headers['user-agent']
    let hasRedirected = false
    for (const { rule, to, statusCode, validator } of redirectMap) {
      if (hasRedirected) {
        break
      }
      if (typeof validator === 'function') {
        validator(req, (...rest) => {
          hasRedirected = true
          redirect(...rest)
        })
      } else if (rule?.test(url) && isMobileUA(UA)) {
        redirect(statusCode || 302, to(url))
        hasRedirected = true
      }
    }
  }
}
